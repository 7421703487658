<template>
  <div class="d-flex align-items-start login-page">
    <div class="content-left" :class="isLogin ? 'width-50' : 'width-64'">
      <div class="d-flex justify-content-center" v-if="isLogin">
        <div class="content-left-wrapper-login">
          <div class="d-flex justify-content-between">
            <div
              v-if="getPlatform() === 'total'"
              class="bg-primary p-2 border rounded"
            >
              <TotalLogoStamp
                :image="
                  require('../../assets/img/PNG/total-express-logo-white.png')
                "
                height="44"
                width="auto"
              />
            </div>
            <div
              v-else-if="getPlatform() === 'e-cross'"
              class="bg-primary p-2 border rounded"
            >
              <img
                :src="require('../../assets/img/PNG/e-cross-logo-bg.png')"
                class="bg-header"
                height="50"
                width="auto"
              />
            </div>
            <div v-else-if="getPlatform() === 'asia'">
              <AsiaLogoStamp
                height="2.12rem"
                width="9rem"
                class="mt-1"
                fill="#13105d"
              />
            </div>
            <div v-else-if="getPlatform() === 'anjun'" class="">
              <img
                :src="require('../../assets/img/PNG/anjun-logo.png')"
                class="bg-header"
                height="50"
                width="auto"
              />
            </div>
            <div v-else-if="getPlatform() === 'tiendamia'" class="">
              <img
                :src="require('../../assets/img/PNG/tiendamia-logo.png')"
                class="bg-header"
                height="50"
                width="auto"
              />
            </div>
            <div v-else-if="getPlatform() === 'mirakl'" class="mt-1">
              <img
                :src="require('../../assets/img/PNG/mirakl-logo.png')"
                class="bg-header"
                height="28"
                width="auto"
              />
            </div>
            <div v-else-if="getPlatform() === 'skypostal'" class="mt-1">
              <img
                :src="require('../../assets/img/PNG/skypostal-logo.png')"
                class="bg-header"
                height="40"
                width="auto"
              />
            </div>
            <div v-else-if="getPlatform() === 'jt'" class="mt-1">
              <img
                :src="require('../../assets/img/PNG/jt-logo.png')"
                class="bg-header"
                height="40"
                width="auto"
              />
            </div>
            <div v-else-if="getPlatform() === 'correios'" class="">
              <img
                :src="require('../../assets/img/PNG/correios-logo.png')"
                class="bg-header"
                height="40"
                width="auto"
              />
            </div>
            <div
              v-else-if="getPlatform() === 'shopee'"
              class="shopee-logo-login"
            >
              <img
                :src="require('../../assets/img/PNG/shopee-logo.png')"
                class="bg-header"
                height="50"
                width="auto"
              />
            </div>
            <div v-else-if="getPlatform() === 'csat'" class="csat-logo-login">
              <img
                :src="require('../../assets/img/PNG/csat-logo.png')"
                class="bg-header"
                height="50"
                width="auto"
              />
            </div>
            <div v-else>
              <IconLogo height="2.12rem" width="9rem" class="mt-1" />
            </div>

            <div>
              <LangButton class="mt-1" />
            </div>
          </div>

          <LoginForm
            :errors="errors"
            :loading="loading"
            :platform="getPlatform()"
            @authenticate="authenticate($event)"
            @openResetPasswordModal="openResetPasswordModal"
          />

          <div v-if="getPlatform() !== 'e-cross'" style="padding-bottom: 4rem">
            <div class="heading-3 mb-1">
              {{ $t("loginPage.notRegisteredYet") }}?
            </div>
            <a @click="switchIsLogin()" class="link-2">
              {{ $t("userCreatePage.title") }} >
            </a>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end" v-if="!isLogin">
        <div class="content-left-wrapper-create">
          <UserCreate
            v-on:login="authenticateNewUser"
            @switchIsLogin="switchIsLogin"
            @sellerCreated="sellerCreated"
            :platform="user.platform"
          />
        </div>
      </div>
      <footer
        v-if="isLogin && getPlatform() !== 'e-cross'"
        class="login-footer"
      >
        {{ $t("loginPage.checkOur") }}
        <a
          data-toggle="modal"
          v-b-modal.modal-agreement
          ref="modal-agreement"
          class="link-1"
          >{{ $t("loginPage.generalConditionsService") }}</a
        >
      </footer>
    </div>
    <aside
      class="content-right"
      :class="isLogin ? 'width-50' : 'width-36'"
      :style="dynamicBackground"
    >
      <div class="d-flex justify-content-center">
        <div class="top-card" v-if="isLogin && getPlatform() !== 'e-cross'">
          <div>
            {{ getFirstSlogan() }}
          </div>
          <div>
            {{ getSecondSlogan() }}
          </div>
        </div>
      </div>
      <a
        v-if="getPlatform() !== 'e-cross'"
        class="support-button"
        href="https://nobordistinc.freshdesk.com/support/home"
      >
        {{ $t("contactUs") }}
        <i class="far fa-comment-alt ml-1"></i>
      </a>
    </aside>

    <NbModal
      id="modalPassword"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">{{ $t("loginPage.forgotPassword") }}?</div>
      </template>
      <template v-slot:body>
        <Alert
          v-for="(error, index) in resetPasswordErrors"
          :key="index"
          class="mb-2"
          type="error"
          icon="x-circle"
          :message="typeof error === 'string' ? error : error[0]"
        />
        <Alert
          v-if="resetPasswordEmailSent"
          class="mb-2"
          type="success"
          icon="far fa-check-circle"
          :message="$t('loginPage.resetPasswordSent')"
        />
        <NbTextInput
          required
          id="reset-email"
          name="E-mail"
          class="mb-2"
          :placeholder="$t('placeholderEmail')"
          v-model="resetPasswordEmail"
        />
      </template>
      <template v-slot:footer>
        <div class="mr-3">
          <button
            @click="resetPassword"
            class="button button-primary float-right"
          >
            <span v-if="loading">
              <span class="spinner-border spinner-border-sm mr-2"></span>
              {{ $t("loading") }}
            </span>
            <span v-if="!loading">{{
              $t("loginPage.sendResetPasswordInstructions")
            }}</span>
          </button>
        </div>
      </template>
    </NbModal>

    <b-modal
      id="modal-agreement"
      scrollable
      size="xl"
      :title="`${capitalizeFirstLetter(
        user.platform
      )} General Conditions of Service`"
    >
      <Agreement />
      <template #modal-footer>
        <form v-on:submit.prevent="acceptAgreement">
          <div v-if="userData.length">
            <b-form-checkbox
              id="check-agreement"
              v-model="agreementAccepted"
              name="check-agreement"
              value="true"
              required
            >
              {{ $t("loginPage.agreementText") }}
            </b-form-checkbox>
            <div class="d-flex flex-row-reverse">
              <button type="submit" class="button-secondary m-2">
                {{ $t("loginPage.iAgree") }}
              </button>
              <button
                type="button"
                class="button-light m-2"
                @click="$bvModal.hide('modal-agreement')"
              >
                {{ $t("cancel") }}
              </button>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>
<script>
const axios = require("axios");
/* import LoadingPageLogin from '@/components/loadings/LoadingPageLogin.vue'; */
import UserService from "../../services/UserService";
import PasswordService from "../../services/PasswordService";
import Agreement from "../Agreement";
import UserCreate from "../../views/user/UserCreate";
import IconLogo from "@/components/icons/IconLogo.vue";
import TotalLogoStamp from "@/components/icons/TotalLogoStamp.vue";
import AsiaLogoStamp from "@/components/icons/AsiaLogoStamp.vue";
import LangButton from "../../components/buttons/LangButton.vue";
import LoginForm from "../../components/login/LoginForm.vue";
import NbModal from "../../components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import Alert from "@/components/alerts/Alert.vue";

const userService = new UserService();
const passwordService = new PasswordService();
export default {
  name: "login",
  components: {
    Agreement,
    UserCreate,
    /* LoadingPageLogin, */
    IconLogo,
    TotalLogoStamp,
    AsiaLogoStamp,
    LangButton,
    LoginForm,
    NbModal,
    NbTextInput,
    Alert,
  },
  data: function () {
    return {
      user: {
        email: "",
        password: "",
        agreement: false,
        platform: "nobordist",
      },
      authenticatePlatform: "nobordist",
      userData: {},
      errors: [],
      loading: false,
      resetPasswordData: {
        email: "",
        platform: this.setPlatform(),
        url: window.location.href.replace(window.location.pathname, ""),
      },
      resetPasswordEmail: "",
      resetPasswordErrors: [],
      resetPasswordEmailSent: false,
      agreementAccepted: "false",
      isLogin: true,
      dynamicBackground: {
        backgroundImage: `url(${
          this.getPlatform() !== "e-cross"
            ? require("@/assets/img/bg-login-nb.png")
            : require("@/assets/img/bg-login-e-cross.png")
        })`,
      },
    };
  },
  created() {
    this.configurePlatforms();
  },
  methods: {
    acceptAgreement() {
      if (
        this.agreementAccepted === "true" ||
        this.getPlatform() === "e-cross"
      ) {
        this.user.agreement = true;
        axios
          .post(
            process.env.VUE_APP_LOGISTICS_API_URL +
              `authenticate/${this.authenticatePlatform}/seller`,
            {
              //`authenticate/${this.authenticatePlatform}/seller`
              email: this.user.email,
              password: this.user.password,
              agreement: this.user.agreement,
            }
          )
          .then((response) => {
            if (response.data.agreement) {
              this.acceptAndLogin();
            }
          })
          .catch((error) => {
            this.loading = false;
            if (
              error.response.data.hasOwnProperty("error") &&
              error.response.data.error.hasOwnProperty("user_authentication")
            ) {
              this.errors = [error.response.data.error.user_authentication];
            } else {
              this.errors = [error.message];
            }
          });
      }
    },
    acceptAndLogin() {
      localStorage.removeItem("columns_configuration"); //force reset columns_configuration
      userService.setLoggedInUser(this.userData);
      let redirectToPath = "/";
      if (this.$route.query?.intended) {
        redirectToPath = this.$route.query.intended;
      }

      this.$router.push(redirectToPath).then(() => {
        window.location.reload();
      });
    },
    removeFromErrors(array, index) {
      array.splice(index, 1);
    },
    openResetPasswordModal() {
      this.resetPasswordEmail = "";
      this.resetPasswordErrors = [];
      this.resetPasswordEmailSent = false;
    },
    authenticate(user) {
      this.loading = true;
      this.user.email = user.email;
      this.user.password = user.password;
      axios
        .post(
          process.env.VUE_APP_LOGISTICS_API_URL +
            `authenticate/${this.authenticatePlatform}/seller`,
          {
            //authenticate/${this.authenticatePlatform}/seller
            email: user.email,
            password: user.password,
            platform: this.user.platform,
          }
        )
        .then((response) => {
          localStorage.setItem("platform", this.getPlatform());
          const agreement = response.data.agreement;
          const parsedUser = JSON.stringify(response.data);
          this.userData = parsedUser;
          this.loading = false;

          if (!agreement) {
            this.user.email = user.email;
            this.user.password = user.password;
            this.$refs["modal-agreement"].click();
            return;
          }
          this.acceptAndLogin();
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response.data.hasOwnProperty("error") &&
            error.response.data.error.hasOwnProperty("user_authentication")
          ) {
            this.errors = [error.response.data.error.user_authentication];
          } else {
            this.errors = [error.message];
          }
        });
    },
    authenticateNewUser(user, password) {
      this.user.email = user;
      this.user.password = password;
      this.authenticate(this.user);
    },
    setPlatform() {
      let platform = window.location.pathname
        .replace("/", "")
        .replace("-login", "");
      return platform === "nb" ? "nobordist" : platform;
    },
    resetPassword() {
      this.loading = true;
      this.resetPasswordData.email = this.resetPasswordEmail;

      passwordService
        .sendResetPasswordEmail(this.resetPasswordData, this.getPlatform())
        .then(() => {
          this.loading = false;
          this.resetPasswordEmailSent = true;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.hasOwnProperty("messages")) {
            this.resetPasswordErrors = [error.response.data.messages];
          } else {
            this.resetPasswordErrors = [error.message];
          }
        });
    },
    switchIsLogin() {
      this.isLogin = !this.isLogin;
    },
    sellerCreated() {
      this.$helpers.toast(
        "Account Created, please check confirmation in you email and complete password there",
        "success",
        4000
      );
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getPlatform() {
      return this.$helpers.getPlatform();
    },
    configurePlatforms() {
      this.user.platform = this.getPlatform();
      if (this.user.platform === "asia") {
        this.authenticatePlatform = "asia_shipping";
        return;
      }
      this.authenticatePlatform = this.getPlatform();
    },
    getFirstSlogan() {
      let finalText = "Ship, track and clear customs.";

      if (this.getPlatform() === "tiendamia") {
        finalText = "A forma mais barata de comprar";
      }
      if (this.getPlatform() === "jt") {
        finalText = "Express your online business";
      }

      return finalText;
    },
    getSecondSlogan() {
      let finalText = "Your cross border sales to Latam";
      if (this.getPlatform() === "e-cross") {
        finalText = "Fell Local, Sell Global";
      }
      if (this.getPlatform() === "tiendamia" || this.getPlatform() === "jt") {
        finalText = "";
      }
      return finalText;
    },
  },
};
</script>
<style lang="scss" scoped>
//new layout
.width-50 {
  width: 50vw;
}
.width-36 {
  width: 36vw;
}
.width-64 {
  width: 64vw;
}

.content-left,
.content-right {
  transition: all 1s ease;
  height: 100vh;
}
//content left
.content-left {
  background-color: var(--background);
  overflow-y: auto;
}
.content-left-wrapper-login {
  width: 21.5rem;
  margin-top: 2.5rem;
  //background-color: red;
}
.content-left-wrapper-create {
  max-width: 45rem;
  width: 100%;
  padding: 4rem 1rem 0rem 1rem;
}

//content-right
.content-right {
  //background-image: url(../../assets/img/bg-login-nb.png);
  background-size: cover;
}

.form-wrapper {
  margin-top: 5.25rem;
  border-bottom: 2px solid var(--gray-10);
  padding-bottom: 4.56rem;
  margin-bottom: 1.5rem;
}

.login-footer {
  color: var(--gray-60);
  text-align: center;
  font: normal normal 600 14px/20px var(--font-family-base);
  letter-spacing: 0px;
  width: 50vw;
  height: 2.68rem;
  position: absolute;
  bottom: 0;
  padding: 0.75rem;
  background: var(--gray-05);
}

.top-card {
  max-width: 21rem;
  padding: 1.5em 3em;
  background-color: var(--primary);
  color: var(--white);
  text-align: center;
  font: normal normal normal 16px/24px var(--font-family-base);
  letter-spacing: 0px;
}

.support-button {
  background: var(--primary-70);
  border: 2px solid var(--gray-20);
  color: var(--white);
  border-radius: 20px;
  text-align: left;
  font: normal normal 600 16px/16px var(--font-family-base);
  letter-spacing: 0px;
  padding: 0.56rem 1rem;

  position: absolute;
  bottom: 1.5rem;
  right: 3.9rem;
}
.support-button:hover {
  background: var(--primary-70-hover);
}

.shopee-logo-login img {
  height: 60px;
  width: auto;
  background-color: #ef5436;
  padding: 0.3rem;
  border-radius: 5px;
}

.csat-logo-login {
  display: flex;
  align-items: center;
  img {
    height: 2rem;
    padding: 0.3rem;
  }
}

//end new layout

.login-banner {
  height: 100vh;
  background-image: url(../../assets/img/login-banner.jpg);
  background-size: cover;
}
.login-wrapper {
  animation: animate-login-screen 2.3s ease-in;
  animation-fill-mode: forwards;
  width: 345px;
}
.login-page {
  height: 100vh;
  overflow: auto;
  background: var(--background);
  //background: var(--loginBG); //talvez??
  //color: var(--white);
  //fill: var(--white);

  a {
    margin-top: 0.9rem;
  }
}
@keyframes animate-login-screen {
  0% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  50% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  100% {
    margin-top: -5px;
    height: 80px;

    transform: scale(0.5);
  }
}
</style>
